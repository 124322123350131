<template>
    <v-bottom-navigation fixed app height="64" class="px-2 py-2 navbar-container white-background">
                <div class="d-flex align-center navbar-icons">
                <!-- Home Icon -->
                <div class="navbar-icon" @click="redirectTo('home')">
                    <img class="icon-class" :src="[highlight_icon == 'home' ? 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_home.svg' : 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_home.svg']" alt="home-icon">
                    <p class="ma-0" :class="[highlight_icon == 'home' ? 'font-weight-semi-bold light-blue-color-text' : 'light-grey-color-text']">{{ $t("Customer.Home.Home") }}</p>
                </div>

                <!-- Pharmacy Icon -->
                <div class="navbar-icon" @click="redirectTo('pharmacy')">
                    <img class="icon-class" :src="[highlight_icon == 'pharmacy' ? 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_pharmacy.svg' : 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_pharmacy.svg']" alt="pharmacy-icon">
                    <p class="ma-0" :class="[highlight_icon == 'pharmacy' ? 'font-weight-semi-bold light-blue-color-text' : 'light-grey-color-text']">{{ $t("Customer.Home.Pharmacy") }}</p>
                </div>

                <!-- Consultation Icon -->
                <div class="navbar-icon" @click="redirectTo('consultation')">
                    <img class="icon-class" :src="[highlight_icon == 'consultation' ? 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_consultation.svg' : 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_consultation.svg']" alt="consultation-icon">
                    <p class="ma-0" :class="[highlight_icon == 'consultation' ? 'font-weight-semi-bold light-blue-color-text' : 'light-grey-color-text']">{{ $t("Customer.Home.Consultation") }}</p>
                </div>

                <!-- My Orders
                <div class="navbar-icon" @click="redirectTo('my_orders')">
                    <img class="icon-class" :src="[highlight_icon == 'my_orders' ? 'https://d3vsnl8idgwrlw.cloudfront.net/bottom_nav_icon_active_my_orders.svg' : 'https://d3vsnl8idgwrlw.cloudfront.net/bottom_nav_icon_inactive_my_orders.svg']" alt="my-orders-icon">
                    <p class="ma-0" :class="[highlight_icon == 'my_orders' ? 'font-weight-semi-bold light-blue-color-text' : 'light-grey-color-text']">My Orders</p>
                </div> -->

                <!-- Profile Icon -->
                <div class="navbar-icon" @click="redirectTo('profile')">
                    <img class="icon-class" :src="[highlight_icon == 'profile' ? 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_profile.svg' : 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_profile.svg']" alt="profile-icon">
                    <p class="ma-0" :class="[highlight_icon == 'profile' ? 'font-weight-semi-bold light-blue-color-text' : 'light-grey-color-text']">{{ $t("Customer.Home.Profile") }}</p>
                </div>
            </div>
        </v-bottom-navigation>
</template>

<script>
export default {
    name: 'BottomNavigationBarComponent',
    component: {},
    props: {
        highlight_icon: {
            type: String,
            required: true
        }
    },
    data() {
        return {

        };
    },
    mounted() {
        if (this.$store.state.locale !== "") {
			this.$i18n.locale =  this.$store.state.locale;
		}
		else {
			this.$i18n.locale = "mm";
			this.$store.dispatch("changeLocale", this.$i18n.locale);
		}

    },
    methods: {
        redirectTo(destination) {
            this.currentCustomer = this.$cookies.get("customerToken");

            if (destination === 'home') {
                if (!window.xm) {
                    this.$router.push({
                        name: 'CustomerHomeScreen'
                    });
                } else {
                    this.$router.push({
                        name: 'CommonHomePage'
                    });
                }
            } else if (destination === 'pharmacy') {
                if (!window.xm) {
                    this.$router.push({
                        name: 'CustomerHomeScreen'
                    });
                } else {
                    this.$router.push({
                        name: "ePharmacyHomePage"
                    });
                }
            } else if (destination === 'consultation') {
                this.$router.push({
                    name: "CustomerHomeScreen"
                });
            } else if (destination === 'my_orders') {
                if (this.currentCustomer) {
                    this.$router.push({
                        name: "MyOrdersPage"
                    });
                } else {
                    this.$router.push({
                        name: 'Login'
                    });
                }
            } else if (destination === 'profile') {
                if (this.currentCustomer) {
                    this.$router.push({
                        name: "UserProfile"
                    });
                } else {
                    this.$router.push({
                        name: 'Login'
                    });
                }
            } else {
                console.log('ERROR: Invalid destination for redirection');
            }
        } 
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';

.navbar-container {
    margin: 0px;
    box-shadow: 0px -4px 24px rgba(0, 36, 74, 0.1);
}

.navbar-icons {
    width: 100%;
    justify-content: space-evenly;

    p {
        word-break: break-all;

        @media (max-width: 376px) {
            font-size: 10px;
        }

        @media (min-width: 376px) {
            font-size: 12px;
        }
    }

    .icon-class {
        @media (max-width: 376px) {
            width: 25px;
		    height: 25px;
        }

        @media (min-width: 376px) {
            width: 28px;
		    height: 28px;
        }
    }
}

.navbar-icon {
    width: 20%;
}

.navbar-container .v-item-group.v-bottom-navigation {
    box-shadow: unset !important;
}
</style>

<style lang="scss">

</style>